define("@herewatch/frontend/components/toggle", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="toggle flex items-center justify-between {{@wrapperClass}}">
      <span role="checkbox" tabindex="0" aria-checked="false"
          class="relative inline-flex items-center justify-center flex-shrink-0 w-10 h-5 cursor-pointer group focus:outline-none"
          ...attributes {{on "click" (fn this.toggle this.isToggled)}}>
          <span aria-hidden="true" class="{{if this.isToggled this.activeColorClass "bg-gray-100"}} {{if this.isToggled "is-toggled" "not-toggled"}} absolute h-4 w-9
              mx-auto rounded-full transition-colors ease-in-out duration-200"></span>
          <span aria-hidden="true" class="{{
              if this.isToggled " translate-x-5" "translate-x-0" }} absolute left-0 inline-block h-5 w-5 border
              border-gray-200 rounded-full bg-white shadow transform group-focus:shadow-outline
              group-focus:border-blue-300 transition-transform ease-in-out duration-200"></span>
      </span>
      {{#if @label}}
          <span class="ml-2 text-sm dark:text-gray-100 {{@labelClass}}">{{@label}}</span>
      {{/if}}
      {{yield}}
  </div>
  */
  {
    "id": "tEsCOkNl",
    "block": "{\"symbols\":[\"@labelClass\",\"@label\",\"@wrapperClass\",\"&attrs\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"toggle flex items-center justify-between \",[32,3]]]],[12],[2,\"\\n    \"],[11,\"span\"],[24,\"role\",\"checkbox\"],[24,\"tabindex\",\"0\"],[24,\"aria-checked\",\"false\"],[24,0,\"relative inline-flex items-center justify-center flex-shrink-0 w-10 h-5 cursor-pointer group focus:outline-none\"],[17,4],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"toggle\"]],[32,0,[\"isToggled\"]]],null]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[15,0,[31,[[30,[36,2],[[32,0,[\"isToggled\"]],[32,0,[\"activeColorClass\"]],\"bg-gray-100\"],null],\" \",[30,[36,2],[[32,0,[\"isToggled\"]],\"is-toggled\",\"not-toggled\"],null],\" absolute h-4 w-9\\n            mx-auto rounded-full transition-colors ease-in-out duration-200\"]]],[12],[13],[2,\"\\n        \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[15,0,[31,[[30,[36,2],[[32,0,[\"isToggled\"]],\" translate-x-5\",\"translate-x-0\"],null],\" absolute left-0 inline-block h-5 w-5 border\\n            border-gray-200 rounded-full bg-white shadow transform group-focus:shadow-outline\\n            group-focus:border-blue-300 transition-transform ease-in-out duration-200\"]]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[31,[\"ml-2 text-sm dark:text-gray-100 \",[32,1]]]],[12],[1,[32,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[18,5,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "@herewatch/frontend/components/toggle.hbs"
    }
  });

  let ToggleComponent = (_dec = Ember.computed('activeColor'), _dec2 = Ember._tracked, _dec3 = Ember.computed('args.isToggled', '_isToggled'), _dec4 = Ember._action, (_class = (_temp = class ToggleComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "activeColor", 'green');

      _initializerDefineProperty(this, "_isToggled", _descriptor, this);
    }

    /**
     * The active color class
     *
     * @var {String}
     */
    get activeColorClass() {
      return `bg-${this.activeColor}-400`;
    }
    /**
     * If the toggle is on or off
     *
     * @var {Boolean}
     */


    /**
     * If the toggle is on or off
     *
     * @var {Boolean}
     */
    get isToggled() {
      if (this._isToggled !== null) {
        return this._isToggled;
      }

      return this.args.isToggled || false;
    }
    /**
     * Setter for `isToggled`
     *
     * @void
     */


    set isToggled(isToggled) {
      this._isToggled = isToggled;
    }
    /**
     * Event for on/of toggle
     *
     * @void
     */


    toggle(isToggled) {
      this.isToggled = isToggled === false ? true : false;

      if (typeof this.args.onToggle === 'function') {
        this.args.onToggle(this.isToggled);
      }
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "activeColorClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "activeColorClass"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "_isToggled", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "isToggled", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isToggled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype)), _class));
  _exports.default = ToggleComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ToggleComponent);
});