define("@herewatch/frontend/services/fetch", ["exports", "fetch", "downloadjs"], function (_exports, _fetch, _downloadjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class FetchService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "host", 'https://api.here.watch');

      _defineProperty(this, "namespace", '');

      _defineProperty(this, "headers", {
        'Content-Type': 'application/json'
      });

      _defineProperty(this, "credentials", 'same-origin');
    }

    /**
     * Parses the JSON returned by a network request
     *
     * @param  {Object} response A response from a network request
     * @return {Object}          The parsed JSON, status from the response
     *
     * @return {Promise}
     */
    parseJSON(response) {
      return new Promise((resolve, reject) => response.json().then(json => resolve({
        statusText: response.statusText,
        status: response.status,
        ok: response.ok,
        json
      })).catch(() => {
        reject(new Error('Oops! Something went wrong when handling your request.'));
      }));
    }
    /**
     * The base request method
     *
     * @param {String} path
     * @param {String} method
     * @param {Object} data
     * @param {Object} options
     *
     * @return {Promise}
     */


    request(path, method = 'GET', data = {}, options = {}) {
      return new Promise((resolve, reject) => {
        return (0, _fetch.default)(options.externalRequest === true ? path : `${this.host}/${path}`, {
          method,
          credentials: this.credentials,
          headers: { ...(this.headers || {}),
            ...(options.headers || {})
          },
          ...data
        }).then(this.parseJSON).then(response => {
          if (response.ok) {
            return resolve(response.json);
          }

          return reject(new Error(response.json.errors ? response.json.errors.firstObject : response.statusText));
        }).catch(error => {
          reject(error);
        });
      });
    }
    /**
     * Makes a GET request with fetch
     *
     * @param {String} path
     * @param {Object} query
     * @param {Object} options
     *
     * @return {Promise}
     */


    get(path, query = {}, options = {}) {
      const urlParams = !Ember.isBlank(query) ? new URLSearchParams(query).toString() : '';
      return this.request(`${path}${urlParams ? '?' + urlParams : ''}`, 'GET', {}, options);
    }
    /**
     * Makes a POST request with fetch
     *
     * @param {String} path
     * @param {Object} data
     * @param {Object} options
     *
     * @return {Promise}
     */


    post(path, data = {}, options = {}) {
      return this.request(path, 'POST', {
        body: JSON.stringify(data)
      }, options);
    }
    /**
     * Makes a PUT request with fetch
     *
     * @param {String} path
     * @param {Object} data
     * @param {Object} options
     *
     * @return {Promise}
     */


    put(path, data = {}, options = {}) {
      return this.request(path, 'PUT', {
        body: JSON.stringify(data)
      }, options);
    }
    /**
     * Makes a DELETE request with fetch
     *
     * @param {String} path
     * @param {Object} data
     * @param {Object} options
     *
     * @return {Promise}
     */


    delete(path, data = {}, options = {}) {
      return this.request(path, 'DELETE', {
        body: JSON.stringify(data)
      }, options);
    }
    /**
     * Makes a PATCH request with fetch
     * @param {String} path
     * @param {Object} data
     * @param {Object} options
     *
     * @return {Promise}
     */


    patch(path, data = {}, options = {}) {
      return this.request(path, 'PATCH', {
        body: JSON.stringify(data)
      }, options);
    }
    /**
     * Downloads blob of the request path to user
     *
     * @param {String} path
     * @param {Object} query
     * @param {Object} options
     *
     * @return {Promise}
     */


    download(path, query = {}, options = {}) {
      return new Promise((resolve, reject) => {
        return (0, _fetch.default)(`${this.host}/${path}?${!Ember.isBlank(query) ? new URLSearchParams(query).toString() : ''}`, {
          method: 'GET',
          credentials: this.credentials,
          headers: { ...(this.headers || {}),
            ...(options.headers || {})
          }
        }).then(response => response.blob()).then(blob => {
          return resolve((0, _downloadjs.default)(blob, options.fileName, options.mimeType));
        }).catch(error => {
          reject(error);
        });
      });
    }

  }

  _exports.default = FetchService;
});